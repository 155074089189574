{
}
.Spellbind {
  text-align: center;
}

.sqr {
  text-align: center; font-size: x-large; width: 2ch; border-width: 1px; border-color: lightgrey
}

.rightdown {
  border-top-right-radius: 8px;
  border-right-color: black;
  border-right-width: 2px;
}
.downright {
  border-bottom-left-radius: 8px;
  border-bottom-color: black;
  border-bottom-width: 2px;
}

.window {
  margin: 2em;
  padding : 2em;
}

table {
}

.component {
  float: left;
  margin-top: 2em;
  padding-right: 2em;
}

.title {
}
.words {
}
.legend {
  margin-top: 2em;
}
.btn {
  margin-left: 1em;
}
.verify {
}
.saveOutput {
  clear: both;
}
.btnLbl {
  font-size: 1.5em;
  margin-right: 1em;
  font-weight: bold;
}