.Spellfound {
  text-align: center;
}

.sqr {
  text-align: center; font-size: x-large; width: 2ch; border-width: 0.1px; border-color: lightgrey
}

.rightdown {
  border-top-right-radius: 8px;
  border-right-color: black;
  border-right-width: 2px;
}
.downright {
  border-bottom-left-radius: 8px;
  border-bottom-color: black;
  border-bottom-width: 2px;
}

table {
  border-spacing: 0
}

.component {
  padding : 2em;
  float: left
}
.legend {
  padding : 2em;
  font-size: medium;
}
.title {
  padding-left : 2em;
}
.verify {
  padding: 2em;
  text-align: center;
}